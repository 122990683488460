import React from 'react';
import {
  Legend,
  Cell,
  Pie,
  Line,
  PieChart,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { inject } from 'mobx-react';
import {
  faNewspaper,
  faChartBar,
  faFile,
  faDatabase,
} from '@fortawesome/free-solid-svg-icons';
import { Grid, Typography } from '@mui/material';

import MetricCard from '@dapclient/components/metrics/MetricCard';
import {
  Metrics as DapMetrics,
} from '@dapclient/components/page/Metrics';
import MetricsModel from '@extensions/models/Metrics';
import MetricsUtils from '@dapclient/utils/MetricsUtils';

import theme from '@extensions/services/Theme';
import { styled } from '@mui/material/styles';

const styleObject = {
  statNum: {
    color: theme.palette.metrics ? theme.palette.metrics.main : theme.palette.secondary.main,
    fontWeight: 100,
  },
  statLabel: { color: '#0a0a0a !important', },
  cardContent: { background: '#f3f3f3', },
  accordionSummary: { background: theme.palette.primary.main, },
  detailsText: { color: 'white !important', },
  expandIcon: { color: 'white !important' },
  downloadStatContainer: {
    padding: 20,
  },
};

const StyledSmallChartSpan = styled('span')(({
  width: '100%'
}));
export class Metrics extends DapMetrics {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderPublicationCount = (metrics: MetricsModel) => {
    const { publicationStats } = metrics;
    if (publicationStats) {
      const pieChartData = Object.entries(publicationStats.pubsCountsByType);
      return (
        <MetricCard
          icon={faNewspaper}
          count={publicationStats.totalNumPubs}
          countSubtitle={'Publications'}
          styleObject={styleObject}
        >
          <StyledSmallChartSpan>
            <Typography gutterBottom>Publications by Type</Typography>
            <ResponsiveContainer height={250} width="100%">
              <PieChart width={250} height={250}>
                <Pie
                  data={pieChartData}
                  fill="#8884d8"
                  dataKey="1"
                  nameKey="0"
                  label={false}
                  labelLine={false}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={this.getColor(index)} />
                  ))}
                </Pie>
                <Legend />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </StyledSmallChartSpan>
        </MetricCard>
      );
    }
    return null;
  };

  renderDatasetCount = (metrics: MetricsModel) => {
    if (metrics.datasetCountsByMonth.length === 0) {
      return null;
    }

    const barColor = MetricsUtils.getBarColor(theme);
    const xInterval = this.getXAxisIntervalForTicks(
      metrics.datasetCountsByMonth.length,
      5
    );

    return (
      <MetricCard
        icon={faChartBar}
        count={metrics.datasetCount}
        countSubtitle={'Datasets'}
        styleObject={styleObject}
      >
        <StyledSmallChartSpan>
          <Typography gutterBottom>Cumulative dataset count</Typography>
          <ResponsiveContainer height={250} width="100%">
            <LineChart
              data={metrics.datasetCountsByMonth}
              margin={{ bottom: 45, right: 15 }}
            >
              <XAxis
                mirror={true}
                interval={xInterval}
                dataKey="0"
                tickFormatter={(tickValue) =>
                  this.formatLaravelxAxisTicks(
                    tickValue,
                    metrics.datasetCountsByMonth[0][0],
                    metrics.datasetCountsByMonth[
                    metrics.datasetCountsByMonth.length - 1
                    ][0]
                  )
                }
                angle={30}
                dy={35}
              />
              <YAxis
                mirror={true}
                tickFormatter={MetricsUtils.formatYAxisTick}
              />
              <Line dataKey="1" stroke={barColor} dot={false} strokeWidth={2} />
              <Tooltip
                labelFormatter={this.formatLaravelCountsTooltipLabel}
                formatter={(value, name, props) => [value, 'Datasets']}
              />
            </LineChart>
          </ResponsiveContainer>
        </StyledSmallChartSpan>
      </MetricCard>
    );
  };

  renderFilesStored = (metrics: MetricsModel) => {
    return (
      <MetricCard
        icon={faFile}
        count={metrics.fileCount}
        countSubtitle={'Files Stored'}
        styleObject={styleObject}
      />
    );
  };

  renderBytesStored = (metrics: MetricsModel) => {
    return (
      <MetricCard
        icon={faDatabase}
        count={metrics.fileSizeStat.value}
        countSubtitle={`${metrics.fileSizeStat.symbol} Stored`}
        styleObject={styleObject}
      />
    );
  };

  renderContent = (metrics: MetricsModel, cardClasses) => {
    // override with a2e's card classes

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {this.renderUsersRegistered(metrics, styleObject)}
        </Grid>
        <Grid item xs={6}>
          {this.renderProjectCount(metrics, styleObject)}
        </Grid>
        <Grid item xs={3}>
          {this.renderFilesStored(metrics)}
        </Grid>
        <Grid item xs={3}>
          {this.renderBytesStored(metrics)}
        </Grid>
        <Grid item xs={3}>
          {this.renderDatasetCount(metrics)}
        </Grid>
        <Grid item xs={3}>
          {this.renderPublicationCount(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadFileSizes(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadFileCounts(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadUserCount(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadDatasetCount(metrics)}
        </Grid>
        {this.renderWebsiteUsage()}
      </Grid>
    );
  };
}

export default inject((store: any) => ({
  metricsService: store.metricsService,
}))(Metrics);
