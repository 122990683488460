import React from 'react';
import { inject } from 'mobx-react';
import { Typography } from '@mui/material';

import {
  DocumentLanding as DapDocumentLanding
} from '@dapclient/components/page/DocumentLanding';
import Link from '@extensions/components/core/Link';
import theme from '@extensions/services/Theme';

export class DocumentLanding extends DapDocumentLanding {
  getLinkToUpload = (): React.ReactNode => {
    return (
      <Typography 
        sx={{
          color: theme.palette.grey[700]
        }}
      >
        Need to upload data? Go to the{' '}
        <Link to="/upload">data upload page</Link> to get started.
      </Typography>
    );
  };
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
  historyService: store.historyService,
}))(DocumentLanding);
