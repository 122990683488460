import React from 'react';
import { Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const StyledRootDiv = styled('div')(({}))

export interface IExternalAppProps extends RouteComponentProps {
  className?: string;
}

export interface IExternalAppState { }

@observer
export class ExternalApp extends React.Component<
  IExternalAppProps,
  IExternalAppState
> {
  render() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const encodedExternalUrl = searchParams.get('external-url');
    const encodedTitle = searchParams.get('title');
    let body: React.ReactNode;
    if (!encodedExternalUrl) {
      body = <Typography>Malformed URL</Typography>;
    } else {
      body = (
        <iframe
          title={
            encodedTitle ? decodeURIComponent(encodedTitle) : 'External App'
          }
          id="external-app"
          src={decodeURI(encodedExternalUrl)}
          scrolling="no"
          style={{
            overflow: 'hidden',
            height: '2236px',
            width: '100%',
            boxShadow: 'none',
            border: 'none',
          }}
        />
      );
    }
    return <StyledRootDiv>{body}</StyledRootDiv>;
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(withRouter(ExternalApp));
