import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import {
  faLockOpen,
  faLock,
  faSignOutAlt,
  faBell,
} from '@fortawesome/free-solid-svg-icons';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';
import Link from '@dapclient/components/core/Link';
import CartLink from '@extensions/components/nav/CartLink';
import SignInLink from '@dapclient/components/core/SignInLink';
import { useNotificationService, useSecurityService } from '@extensions/hooks/useService';

const StyledRootDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  padding: '0.5rem 1.3rem',
  lineHeight: 2.5,
  background: theme.palette.secondary.main,
  color: 'rgb(238, 238, 238)',
  alignItems: 'left',
  fontSize: '20px',
}));

const StyledLinkButton = styled('button')(({
  border: 0,
  backgroundColor: 'transparent',
  padding: 0,
  cursor: 'pointer',
}));

export interface IHeaderMenuSRProps {
  className?: string;
}

const HeaderMenuSR = () => {

  const securityService = useSecurityService();
  const notificationService = useNotificationService();

  const { user } = securityService;

  const loggedIn = Boolean(user && user.authenticated);
  const withMfa = Boolean(loggedIn && user?.authenticatedMfa);

  const firstName = user !== null ? user.firstname : "";
  const lastName = user !== null ? user.lastname : "";

  const LinkStyleObject = {
    display: 'flex',
    alignItems: 'left',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&:visited': {
      color: '#eee',
    },
  };

  return (
    <StyledRootDiv>
      {loggedIn && (
        <>
          <Link to="/profile" sx={LinkStyleObject}>
            {`${firstName} ${lastName} `}
            <AddIcon
              icon={withMfa ? faLock : faLockOpen}
              title={withMfa ? "Logged in with MFA token" : "NOT logged in with MFA token"}
            />
          </Link>
          <Link
            onClick={async () => {
              await securityService!.logout();
              window.location.href = '/';
            }}
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.common.white,
              cursor: 'pointer',
              textAlign: 'left',
            }}
          >
            Sign Out <AddIcon icon={faSignOutAlt} />
          </Link>
        </>
      )}
      {!loggedIn && (
        <>
          <SignInLink styleObject={LinkStyleObject}>
            Sign In
          </SignInLink>
          <Link to="/register" sx={LinkStyleObject}>
            Register
          </Link>
        </>
      )}
      <div>
        <CartLink styleObject={{ color: theme.palette.common.white }}/>
        <StyledLinkButton
          title="Show any recent notification(s)"
          onClick={() => {
            notificationService.showNotifications = !notificationService.showNotifications;
          }}
          sx={{
            color: theme.palette.common.white,
          }}
        >
          <AddIcon icon={faBell} style={{ width: 17.5, height: 20 }}/>
        </StyledLinkButton>
      </div>
    </StyledRootDiv>
  );
};

export default observer(HeaderMenuSR);
