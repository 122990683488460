import React from 'react';
import { inject } from 'mobx-react';
import {
  ProjectFilters as DapProjectFilters,
} from '@dapclient/components/project-search/ProjectFilters';
import { MultiList } from '@extensions/components/search-core';

export const PROJECT_KEYWORDS_ID = 'PROJECT_KEYWORDS_ID';
export const REACTIVE_IDS = [PROJECT_KEYWORDS_ID];

export class ProjectFilters extends DapProjectFilters {
  getFilterReactiveIds = () => REACTIVE_IDS;
  renderDatasetCriteria = () => {
    return null;
  };
  renderProjectCriteria = () => {
    return (
      <>
        {this.getDatasetCriteriaTitle()}
        <MultiList
          componentId={PROJECT_KEYWORDS_ID}
          title="Keyword"
          filterLabel="Keyword"
          dataField="keyword.keyword"
          react={{
            and: this.allReactiveIdsExcept(PROJECT_KEYWORDS_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams
        />
      </>
    );
  };
}


export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(ProjectFilters);