import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';

export const INSTRUMENT_REACTIVE_ID = 'InstrumentFilter';

export default class InstrumentFilter extends React.Component<{
  react?: react;
}> {
  render = () => {
    return (
      <MultiList
        className="data-filter"
        componentId={INSTRUMENT_REACTIVE_ID}
        dataField="instrument.keyword"
        title="Instrument"
        filterLabel="Instrument"
        showSearch={false}
        react={this.props.react}
        URLParams={true}
      />
    );
  };
}
