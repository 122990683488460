import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';

export const PROJECT_REACTIVE_ID = 'ProjectFilter';

export default class ProjectFilter extends React.Component<{
  fixedValue?: string
  react?: react;
}> {
  render = () => {
    const { fixedValue } = this.props;
    return (
      <div style={fixedValue ? {'display': 'none'} : {}}>
        <MultiList
          className="data-filter"
          componentId={PROJECT_REACTIVE_ID}
          dataField="project_name.keyword"
          title="Project"
          filterLabel="Project"
          showSearch={false}
          react={this.props.react}
          URLParams={!Boolean(fixedValue)}
          defaultValue={fixedValue ? [fixedValue] : undefined}
          showFilter={!Boolean(fixedValue)}
        />
      </div>
    );
  };
}

