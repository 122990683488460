import React from 'react';
import { inject } from 'mobx-react';
import { Typography } from '@mui/material';

import {
    NewPublication as DapNewPublication,
} from '@dapclient/components/page/NewPublication';

export class NewPublication extends DapNewPublication {
    renderTitle = () => {
        return <Typography variant="h1">Add a Publication</Typography>
    }
}

export default inject((store: any) => ({
    // TODO: Put any services you need here
    // EXAMPLE => metricsService: store.metricsService
}))(NewPublication);
