import { inject } from 'mobx-react';
import { Typography } from '@mui/material';

import {
  Acknowledge as DapAcknowledge,
} from '@dapclient/components/page/Acknowledge';
import { styled } from '@mui/material/styles';

import Link from '@extensions/components/core/Link';

const InfoTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.43,
}));

const TextArea = styled('textarea')(({ theme }) => ({
  '&&': {
    width: '99%',
    height: '105px',
    minHeight: '105px',
    color: theme.palette.grey.A700,
    fontSize: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    resize: 'vertical'
  }
}));

const SiteDOITypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.43,
}));

export class Acknowledge extends DapAcknowledge {

  renderSiteDOI = () => {
    return (
      this.siteDoi &&
      <SiteDOITypography variant="body2">
        Site DOI: <Link to={`https://www.osti.gov/search/semantic:${this.siteDoi}`}>{this.siteDoi}</Link>
      </SiteDOITypography>
    )
  }

  renderTextArea = () => {
    return (
      <>
        <Typography variant="h1" gutterBottom>
          Acknowledging A2e
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          To acknowledge general use of A2e DAP, select:
        </Typography>
        <InfoTypography variant="body1" gutterBottom>
          Click to copy to clipboard:
        </InfoTypography>
        <TextArea
          ref={(textarea) => this.textarea = textarea}
          readOnly
          onClick={this.handleClick}
          value="Data were obtained from the Wind Data Hub funded by U.S. Department of Energy, Office of Energy Efficiency and Renewable Energy's Wind Energy Technologies Office operated and maintained by Pacific Northwest National Laboratory at https://a2e.energy.gov. Please be sure to cite individual datasets used in your work. Recommended citations can be found on specific dataset records."
        />
        <InfoTypography variant="body2">
          For dataset-specific citations, please refer to individual past data orders or specific dataset web pages.
        </InfoTypography>
      </>
    )
  }

}

export default inject((store: any) => ({
  cachingService: store.cachingService,
  notificationService: store.notificationService,
}))(Acknowledge);
